.card-element {
    grid-row: span 1;
    grid-column: span 1;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 10px;
    text-decoration: none;
    background-color: #c9c5c5;
}

.card-text-content {
    font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}

.card-link {
    color: black;
    text-decoration: none;
}

.card-title {
    margin-top: 3px;
    margin-bottom: 3px;
    text-align: center;
}

.card-text {
    margin: 5px;
    font-size: .8rem;
}

.card-image {
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5%;
    border-radius: 10px;
    width: 95%;
}

.card-element:hover {
    box-shadow: 0 8px 16px 0 rgba(13, 13, 13, 0.2)
}

.card-element:hover .card-link .card-text-content {
    color: #717275;
}
