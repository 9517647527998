.sitemap {
  font-size: 0.75rem;
  text-align: center;
  font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
  padding: 0;
  margin-bottom: 10px;
}

.sitemap-link {
  color: #000000;
  text-align: center;
  text-decoration: none;
  height: 100%;
  font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}
    
.sitemap-link:hover {
  color: #3E424B;
  font-weight: bolder;
}