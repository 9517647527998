.Home-Content {
    font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}

.hello {
    font-size: 3rem;
}

.main-text {
    font-size: 1.5rem;
}

.below-text {
    font-size: 1.25rem;
}

.link {
    text-decoration: none;
    color: #006aff;
    font-weight: bold;
}

.link:hover {
    color: #1dc2e7;
}

@media (max-width: 400px) {
    .Home-Content {
        padding: 0 10px;
    }
}


@media (min-width: 400px) 
    and (max-width: 600px) {
    .Home-Content {
        padding: 0 30px;
    }
}

@media (min-width: 600px) 
    and (max-width: 800px) {
    .Home-Content {
        padding: 0 50px;
    }
}

@media (min-width: 800px) {
    .Home-Content {
        padding: 0 100px;
    }
}