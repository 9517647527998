.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 999;
  padding: 0;
}

.contact-heading {
  font-size: 1.2rem;
  margin: auto;
  text-align: center;
  padding-bottom: .5rem;
  padding-top: 2rem;
}
  
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.contact-menu {
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  padding-left: 0;
}
  
.contact-links {
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 1rem;
  font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}
  
.contact-links:hover {
  color: #717275;
  font-weight: bolder;
}

.contact-image {
  width: 1.5rem;
  padding-right: 5px;
}

.contact-links:hover .contact-image {
  filter: invert(47%) sepia(7%) saturate(151%) hue-rotate(187deg) brightness(92%) contrast(88%);
}