.projects-content {
    font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}

.card-wrapper {
    position: relative;
    margin: 50px 0 50px;
}

.card-grid {
    display: grid;
    gap: 20px;
}

.card-grid {
    margin: 0 auto;
    max-width: 100%;
}

/*
.card-grid {
    margin: 0 auto;
    max-width: 100%;
    grid-template-columns: repeat(4, 1fr);
}
*/


@media (max-width: 450px) {

    .projects-content {
        padding: 0 10px;
    }

    .card-grid {
        grid-template-columns: 1fr;
    }
    
}

@media (min-width: 450px)
    and (max-width: 600px) {

    .projects-content {
        padding: 0 30px;
    }

    .card-grid {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (min-width: 600px)
    and (max-width: 800px) {
    
    .projects-content {
        padding: 0 50px;
    }

    .card-grid {

        grid-template-columns: repeat(3, 1fr);
    }
}


@media (min-width: 800px)
    and (max-width: 1500px) {
    .projects-content {
        padding: 0 100px;
    }
    .card-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1500px) {
    .projects-content {
        padding: 0 100px;
    }
    .card-grid {
        grid-template-columns: repeat(4, 1fr);
    } 
}