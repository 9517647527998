.navbar {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 999;
}
  
.logo {
  width: 2.5rem;
  padding: 0;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 90%;
}
  
.navbar-home {
  width: 300px;
  color: #000000;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
  font-weight: 700;
  position: relative;
}
  
.nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  overflow-y: auto;
  max-height: calc(
    100vh - 80px
  );
}
  
.nav-menu::before {
  content: ""; /* Create an empty pseudo-element for spacing */
  flex: 1; /* Fill the available space to push the items to the right */
}
  
.nav-item {
  height: 80px;
}
  
.nav-links {
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}
  
.nav-links:hover {
  color: #717275;
  font-weight: bolder;
}

/*
Don't really like it!

.navbar-home:hover {
  color: #717275;
}

.navbar-home:hover .logo {
  filter: invert(47%) sepia(7%) saturate(151%) hue-rotate(187deg) brightness(92%) contrast(88%);
}
*/