.back {
    color: #242424;
    text-decoration: none;
}

.back:hover {
    color: #717275;
    font-weight: bolder;
}

.project-desc {
    font-size: 1.25rem;
}

.project-title {
    font-size: 2.5rem;
}

.project-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
}

.project-link {
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    top: 0;
    z-index: 999;
    padding: 0;
}

.project-additional {
    text-align: center;
}

@media (max-width: 400px) {
    .Home-Content {
        padding: 0 10px;
    }
}


@media (min-width: 400px) 
    and (max-width: 600px) {
    .project-content {
        padding: 0 30px;
    }
}

@media (min-width: 600px) 
    and (max-width: 800px) {
    .project-content {
        padding: 0 50px;
    }
}

@media (min-width: 800px) {
    .project-content {
        padding: 0 100px;
    }
}